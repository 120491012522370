
import React, { useState, useEffect, Fragment } from "react";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const MANIFEST = '/asset-manifest.json';
const POLL_INTERVAL = 30000;

const VersionContext = React.createContext();

const VersionProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getLatestVersion = async () => {
            const response = await fetch(MANIFEST,
                {
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }
                }
            );
            return await response.text();
        };

        const init = async () => {
            try {
                const latestVersion = await getLatestVersion();
                localStorage.setItem('tend-version', latestVersion);
            } catch (ex) {
                // log to sentry / or something
            } finally {
                setTimeout(pollingCallback, POLL_INTERVAL);
            }
        };

        const pollingCallback = async () => {
            try {
                //console.log('Polling for new version.');
                const currentVersion = localStorage.getItem('tend-version');
                const latestVersion = await getLatestVersion();

                if (currentVersion && currentVersion !== latestVersion) {
                    //console.log('New version available.');
                    setOpen(true);
                }
            } catch (ex) {
                // log to sentry / or something
            }
            setTimeout(pollingCallback, POLL_INTERVAL);
        };

        //if (process.env.NODE_ENV === 'production') {
        init();
        //}

    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const handleRefresh = () => {
        window.location.reload();
    }

    const getProvider = () => {
        return {};
    }

    return (
        <VersionContext.Provider value={getProvider()}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                ContentProps={{
                    sx: {
                        backgroundColor: '#FFFFFF',
                        color: '#1B2935'
                    }
                }}
                message={<div>
                    <strong>New version available</strong>
                    <p style={{marginBottom: '0px'}}>Refresh now, or some<br/>features might not be<br/>available.</p>
                </div>}
                action={<Fragment>
                    <Button size="small" sx={{ color: '#1B2935', textDecoration: 'underline' }} onClick={handleRefresh}>
                        Refresh
                    </Button>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Fragment>}
            />
            {children}
        </VersionContext.Provider>
    );
}

export {
    VersionContext,
    VersionProvider
};

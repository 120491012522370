import React from 'react';
import { Html } from '@react-email/html';

export const Email = ({ url, nameAndSurname, emailAddress, mobileNumber, reasonForEnquiry, yourMessage }) => {

  return (
    <Html lang="en">
      <body>
        <h1>{reasonForEnquiry}</h1>
        <p>Name and surname: <strong>{nameAndSurname}</strong><br/>
        Email address: <strong>{emailAddress}</strong><br/>
        Mobile number: <strong>{mobileNumber}</strong><br/>
        Message: <strong>{yourMessage}</strong></p><br/>
        <img src={`${url}/logo512.png`} height={50} alt="logo" /><br/><br/>
      </body>
    </Html>
  );
}
import React, { Fragment, useEffect, useState } from 'react';
import './CalendarManagementPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/calendar-management/illustration01.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import Card from '../../components/card/Card';

import illustration02 from '../../images/features/calendar-management/illustration02.svg';
import illustration03 from '../../images/features/calendar-management/illustration03.svg';
import illustration04 from '../../images/features/calendar-management/illustration04.svg';
import illustration05 from '../../images/features/calendar-management/illustration05.svg';
import illustration06 from '../../images/features/calendar-management/illustration06.svg';
import illustration07 from '../../images/features/calendar-management/illustration07.svg';
import illustration08 from '../../images/features/calendar-management/illustration08.svg';
import illustration09 from '../../images/features/calendar-management/illustration09.svg';
import illustration10 from '../../images/features/calendar-management/illustration10.svg';
import illustration11 from '../../images/features/calendar-management/illustration11.svg';
import illustration12 from '../../images/features/calendar-management/illustration12.svg';
import illustration13 from '../../images/features/calendar-management/illustration13.svg';
import illustration14 from '../../images/features/calendar-management/illustration14.svg';

const CalendarManagementPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Calendar Management");
        setList(
            <Fragment>
                <ul>
                    <p>Calendar management is an <strong>essential</strong> feature of digital business platforms that helps organisations manage their <strong>time</strong> and <strong>resources effectively</strong>.</p>
                    <br />
                    <p>By <strong>integrating</strong> with other tools, providing <strong>comprehensive scheduling</strong> options, and ensuring <strong>data security</strong>, it enhances <strong>productivity</strong> and <strong>collaboration</strong> within the workplace.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='calendar-management-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section cards-section mt-5'>
                        <div className='heading'>The key component of this feature: </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Appointment Scheduling"}
                                    content={"Allows users to book, reschedule, and cancel appointments easily while sending automated notifications and reminders to reduce no-shows."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Team Coordination"}
                                    content={"Enables team members to view each other's availability and schedules, and facilitates sending and managing meeting invitations."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Resource Allocation"}
                                    content={"Manages the reservation of meeting rooms and equipment while showing the availability of resources in real-time."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Integration"}
                                    content={"Integrates with email systems and communication tools for seamless scheduling, and syncs with project management platforms to align deadlines and tasks."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Time Zone Management"}
                                    content={"Helps manage meetings and appointments across different time zones, automatically adjusting for daylight saving time and other regional changes."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Reporting & Analytics"}
                                    content={"Provides insights into how time is spent across the organisation and analysis measuring frequencies, durations, and their impact on productivity."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Personalisation"}
                                    content={"Allows users to customise calendar views (daily, weekly, monthly) and enables colour-coding for different types of events or priorities."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration08}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Security and Permissions"}
                                    content={"Controls who can view or edit specific calendars and events, ensuring that calendar data is secure and complies with privacy regulations."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration09}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Mobile Accessibility"}
                                    content={"Ensures that calendar updates and notifications are synchronised across all devices, and sends alerts and reminders directly to mobile devices."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration10}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>

                        </div>
                    </div>
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The benefits of calendar management:</div>
                        <List
                            heading={"Increased Productivity"}
                            content={"Organising schedules efficiently, reducing overlaps, and minimising conflicts."}
                            icon={illustration11}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Enhanced Collaboration"}
                            content={"Facilitates better coordination among team members and departments."}
                            icon={illustration12}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Improved Customer Experience"}
                            content={"Ensures timely appointments and follow-ups with clients"}
                            icon={illustration13}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Efficient Resource Utilisation"}
                            content={"Optimizes the use of meeting rooms and other resources."}
                            icon={illustration14}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />

                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default CalendarManagementPage;
import React from 'react';
import './Card.scss';

import colors from '../../_variables.module.scss';

const Card = ({heading = null, content = null, icon = null, backgroundColor = colors.green, headingColor = colors.white, contentColor = colors.white}) => {
    return (
        <div className='card' style={{backgroundColor: backgroundColor}}>
            {heading && <div className='card-heading' style={{color: headingColor}}>
                {heading}
            </div>}
            {icon && <div className='card-img'>
                <img src={icon} alt="icon" />
            </div>}
            {content && <div className='card-content' style={{color: contentColor}}>
                {content}
            </div>}
        </div>
    );
};

export default Card;
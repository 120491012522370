import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_MSAL_AUTH_CLIENT_ID}`, // This is the ONLY mandatory field that you need to supply.
        authority: `${process.env.REACT_APP_MSAL_AUTH_AUTHORITY}${process.env.REACT_APP_MSAL_AUTH_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: `${process.env.REACT_APP_MSAL_AUTH_REDIRECT_URI}`, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: `${process.env.REACT_APP_MSAL_AUTH_POST_LOGOUT_REDIRECT_URI}`, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: `${process.env.REACT_APP_MSAL_AUTH_NAVIGATE_TO_LOGIN_REQUEST_URI}`, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: `${process.env.REACT_APP_MSAL_CACHE_CACHE_LOCATION}`, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: `${process.env.REACT_APP_MSAL_CACHE_STORE_AUTH_STATE_IN_COOKIE}`, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        //console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    //scopes: [`api://${process.env.REACT_APP_MSAL_AUTH_NAME}/public-api.read`]
    scopes: [`api://${process.env.REACT_APP_MSAL_AUTH_NAME}/access_as_user`]
};

export const getClaim = (token) => {
    var base64Url = token?.split('.')[1];
    var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64)?.split('')?.map(function (c) {
      return '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2);
    })?.join(''));
    return JSON.parse(jsonPayload) || null;
  }
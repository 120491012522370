import React from 'react';
import './PageLayout.scss';
import { Helmet } from "react-helmet"

const PageLayout = ({ children, title = "Accelerare", description = "Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth.", keywords = "Accelerare" }) => {
    return (
        <div className='page-layout'>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta nasme="keywords" content={keywords} />
            </Helmet>
            {children}
        </div>
    );
}

export default PageLayout;
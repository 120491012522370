import React, { Fragment } from 'react';
//import { Link } from "react-router-dom";
import './Footer.scss';

import Icon from '../../logo_white.svg'
//import FacebookIcon from '../../images/facebook_icon.svg'
//import TwitterIcon from '../../images/twitter_icon.svg'
//import LinkedinIcon from '../../images/linkedin_icon.svg'

const Footer = () => {
    return (
        <Fragment>
            <div className='footer-component'>
                <div className="container">
                    <div className='row'>
                        <div className='col-12 footer-brand'>
                            <img src={Icon} alt="accelerare" width={"100px"} height={"100px"} />
                            {/*<div className='footer-divider'></div>
                            <div>
                                <p className='brand-title'>ACCELERARE</p>
                                <p className='brand-subtitle'>ENABLE VELOCITY</p>
    </div>*/}
                        </div>
                    </div>
                    {/*<div className='row'>
                        <div className='col-12 footer-social' >
                            <div className='mx-2'>
                                <img src={FacebookIcon} width={"100px"} height={"100px"} alt="icon"/>
                            </div>
                            <div className='mx-2'>
                                <img src={TwitterIcon} width={"100px"} height={"100px"} alt="icon"/>
                            </div>
                            <div className='mx-2'>
                                <img src={LinkedinIcon} width={"100px"} height={"100px"} alt="icon"/>
                            </div>
                        </div>
</div>*/}
                    <div className='row'>
                        <div className='col-12 footer-links'>
                            <div className="footer-text" aria-current="page">Accelerare, all rights reserved. © Copy Right 2023.</div>
                            {/*<div className='nav-divider'></div>
                            <Link className="nav-link" aria-current="page" to="/privacy-policy">Privacy policy</Link>
                            <div className='nav-divider'></div>
                            <Link className="nav-link" aria-current="page" to="/website-privacy-policy">Website privacy policy</Link>
                            <div className='nav-divider'></div>
                            <Link className="nav-link" aria-current="page" to="/cookie-policy">Cookie policy</Link>
                            <div className='nav-divider'></div>
<Link className="nav-link" aria-current="page" to="/terms-of-use">Terms of use</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;
import React, { Fragment, useEffect, useState } from 'react';
import './WebsiteManagementPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/domains/WebsiteManagement.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import Card from '../../components/card/Card';
import illustration01 from '../../images/domains/website-management/illustration01.svg';
import illustration02 from '../../images/domains/website-management/illustration02.svg';
import illustration03 from '../../images/domains/website-management/illustration03.svg';
import illustration04 from '../../images/domains/website-management/illustration04.svg';
import illustration05 from '../../images/domains/website-management/illustration05.svg';
import illustration06 from '../../images/domains/website-management/illustration06.svg';
import illustration07 from '../../images/domains/website-management/illustration07.svg';
import illustration08 from '../../images/domains/website-management/illustration08.svg';
import illustration09 from '../../images/domains/website-management/illustration09.svg';


const WebsiteManagementPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Website Management");
        setList(
            <Fragment>
                <ul>
                    <p>Website management involves <strong>overseeing</strong> all aspects of a website's lifecycle, from its <strong>creation</strong> to ongoing <strong>maintenance</strong> and <strong>optimisation</strong>. This encompasses various tasks such as ensuring <strong>technical functionality</strong>, enhancing <strong>security</strong> measures, and <strong>optimising performance</strong> for better <strong>user experience</strong>. Additionally, website management includes <strong>monitoring analytics</strong> to track traffic, user behaviour, and conversion metrics, enabling <strong>informed decision-making</strong> for continuous <strong>improvement</strong>. </p>
                    <p>Beyond maintenance, website management may involve <strong>integrating</strong> advanced technologies like <strong>AI</strong>, conducting <strong>experiments</strong> for optimisation, and integrating <strong>communication</strong> platforms such as <strong>WhatsApp</strong> for enhanced <strong>user engagement.</strong> </p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='website-management-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The aspects of this domain:</div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Maintainance"}
                                    content={"Regularly updating and managing a website to ensure optimal performance, security, and compatibility."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration01}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Security"}
                                    content={"Encryption, firewalls, and regular security audits to protect against cyber threats."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Experiments"}
                                    content={"Data-driven insights, helping businesses make informed decisions to optimise strategies and achieve improved results."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Platform Review"}
                                    content={"Find out more about your business platform’s performance and pin point areas for improvement."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Lead Qualification"}
                                    content={"Evaluating and categorising potential customers based on their likelihood to convert into paying customers."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Artificial Intelligence"}
                                    content={"Can lead to increased efficiency, better decision-making, improved customer experiences, and a competitive advantage."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"WhatsApp Integration"}
                                    content={"Empowers businesses with a versatile and powerful tool for communication, customer engagement, and marketing."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Data tracking and analysis"}
                                    content={"Tracking of website traffic, user journeys and analysis and interpretation of the data to aid in decision making."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration08}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Performance Optimisation"}
                                    content={"Refining various aspects of a website to enhance user experience and improve search engine rankings."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration09}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>

                        </div>
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default WebsiteManagementPage;
import React, { Fragment, useEffect } from 'react';
import PageLayout from '../components/page-layout/PageLayout';
import Title from '../components/title/Title';
import ContactUsForm from '../components/contact-us-form/ContactUsForm';

const ContactUsPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, []);

  return (
    <Fragment>
      <PageLayout title={"Accelerare | Contact Us"} description={"Accelerare. Let us contact you."}>
        <Title title="Contact Us" center={true}/>
        <ContactUsForm reason='Contact Me' />
      </PageLayout>
    </Fragment>
  );
}

export default ContactUsPage;
import React, { Fragment, useEffect, useState } from 'react';
import './InventoryManagementPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/domains/InventoryManagement.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import Card from '../../components/card/Card';
import illustration01 from '../../images/domains/inventory-management/illustration01.svg';
import illustration02 from '../../images/domains/inventory-management/illustration02.svg';
import illustration03 from '../../images/domains/inventory-management/illustration03.svg';
import illustration04 from '../../images/domains/inventory-management/illustration04.svg';
import illustration05 from '../../images/domains/inventory-management/illustration05.svg';
import illustration06 from '../../images/domains/inventory-management/illustration06.svg';
import illustration07 from '../../images/domains/inventory-management/illustration07.svg';
import illustration08 from '../../images/domains/inventory-management/illustration08.svg';
import illustration09 from '../../images/domains/inventory-management/illustration09.svg';
import illustration10 from '../../images/domains/inventory-management/illustration10.svg';

const InventoryManagementPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Inventory Management");
        setList(
            <Fragment>
                <ul>
                    <li>The <strong>systematic</strong> process of <strong>overseeing</strong> and <strong>controlling</strong> a company's <strong>stock</strong> of products or goods. </li>
                    <li>Involves tasks such as <strong>monitoring</strong> inventory levels, <strong>organising</strong> stock, and <strong>optimising</strong> the flow of goods <strong>from production to sale</strong>. </li>
                    <li>The <strong>primary objective</strong> is to ensure that the right amount of inventory is available when needed, <strong>minimising</strong> the <strong>risk</strong> of shortages or excess. </li>
                    <li><strong>Efficient</strong> inventory management is <strong>crucial</strong> for businesses to <strong>enhance</strong> operational <strong>efficiency</strong>, <strong>reduce</strong> costs, and <strong>improve</strong> overall <strong>performance</strong>.</li>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='inventory-management-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section mt-5'>
                        <div className='d-flex flex-sm-row flex-column justify-content-sm-between'>
                            <div>
                                <div className='heading'>The importance of Inventory Management</div>
                                <div className='text'>
                                    <p>Inventory management systems (<strong>IMS</strong>) encompass intricate <strong>processes</strong> that are <strong>fundamental</strong> to a business's <strong>operational</strong> structure, overseeing the <strong>precise</strong> management of inventory levels to <strong>meet demand efficiently</strong>. This strategic initiative involves <strong>forecasting</strong> demand, strategic <strong>procurement</strong>, efficient <strong>storage</strong> practices, and <strong>accurate tracking</strong> mechanisms. </p>
                                    <p>Internal IMS represent an area ripe for <strong>improvement</strong> across many companies, and when executed effectively, can <strong>greatly enhance</strong> operational <strong>efficiency</strong> and substantially <strong>reduce costs</strong>. Additionally, <strong>proficient management</strong> of stock availability and logistics can <strong>elevate</strong> the level of <strong>customer service</strong> a business provides.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid second-section'>
                        <div className='heading'>This Domain offers:</div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-3 my-1 my-sm-2'>
                                <Card
                                    heading={null}
                                    content={"Inventory Tracking"}
                                    backgroundColor={colors.white}
                                    icon={illustration01}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Demand Forecasting"}
                                    backgroundColor={colors.white}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Order Management"}
                                    backgroundColor={colors.white}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Supplier Management"}
                                    backgroundColor={colors.white}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Warehouse Management"}
                                    backgroundColor={colors.white}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Barcode and RFID Integration"}
                                    backgroundColor={colors.white}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Reporting and Analytics"}
                                    backgroundColor={colors.white}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Inventory Optimisation"}
                                    backgroundColor={colors.white}
                                    icon={illustration08}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"System Integration"}
                                    backgroundColor={colors.white}
                                    icon={illustration09}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-2'>
                                <Card
                                    heading={null}
                                    content={"Alerts and Notifications"}
                                    backgroundColor={colors.white}
                                    icon={illustration10}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>
        </Fragment >
    );
}

export default InventoryManagementPage;
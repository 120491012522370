import React, { Fragment, useEffect, useState } from 'react';
import './DocumentManagementPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/document-management/illustration01.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import Card from '../../components/card/Card';

import illustration02 from '../../images/features/document-management/illustration02.svg';
import illustration03 from '../../images/features/document-management/illustration03.svg';
import illustration04 from '../../images/features/document-management/illustration04.svg';
import illustration05 from '../../images/features/document-management/illustration05.svg';
import illustration06 from '../../images/features/document-management/illustration06.svg';
import illustration07 from '../../images/features/document-management/illustration07.svg';
import illustration08 from '../../images/features/document-management/illustration08.svg';
import illustration09 from '../../images/features/document-management/illustration09.svg';
import illustration10 from '../../images/features/document-management/illustration10.svg';
import illustration11 from '../../images/features/document-management/illustration11.svg';
import illustration12 from '../../images/features/document-management/illustration12.svg';
import illustration13 from '../../images/features/document-management/illustration13.svg';
import illustration14 from '../../images/features/document-management/illustration14.svg';
import illustration15 from '../../images/features/document-management/illustration15.svg';
import illustration16 from '../../images/features/document-management/illustration16.svg';



const DocumentManagementPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Document Management");
        setList(
            <Fragment>
                <ul>
                    <p>The use of <strong>software</strong> and digital tools to create, store, organise, <strong>manage</strong>, and track <strong>documents</strong> and files <strong>within</strong> a business <strong>environment</strong>. This feature <strong>enhances</strong> efficiency, collaboration, security, and accessibility of <strong>documents</strong>.</p>
                    <br />
                    <p>Document management <strong>transforms</strong> how businesses handle their documents, leading to improved <strong>efficiency</strong>, collaboration, and security, while also providing a foundation for <strong>better</strong> business <strong>processes</strong> and <strong>decision-making</strong>.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='document-management-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section cards-section mt-5'>
                        <div className='heading'>More about this feature: </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Creation & Capture"}
                                    content={"This includes tools for creating documents directly within the platform and integration with scanners and Optical Character Recognition (OCR) to digitise paper documents."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Storage & Organisation"}
                                    content={"It involves centralised storage repositories for easy access and management, along with metadata tagging, folders, and categorisation to organise documents logically."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Version Control"}
                                    content={"This feature tracks changes and maintains different versions of documents, allowing users to revert to previous versions if needed."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Access Control"}
                                    content={"It ensures role-based access so that only authorised personnel can view or edit documents, and employs encryption and other security measures to protect sensitive information."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Integration"}
                                    content={"Provides tools for real-time collaboration and integrates seamlessly with other digital business tools like CRM, ERP, and project management software."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Search & Retrieval"}
                                    content={"Advanced search functionalities allow users to quickly locate documents using keywords, tags, or metadata, and full-text search capabilities are included."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Workflow Automation"}
                                    content={"This automates workflows to streamline document approval, review, and distribution processes, and integrates with other business processes and systems (e.g., ERP, CRM)."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration08}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Compliance & Auditing"}
                                    content={"Tools ensure compliance with industry regulations and standards, and audit trails are available to track document history and user actions."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration09}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Reporting & Analytics"}
                                    content={"Analytical tools provide insights into document usage, workflows, and efficiency, and reporting features help monitor compliance and operational metrics."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration10}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The benefits of document management</div>
                        <List
                            heading={"Increase Efficiency"}
                            content={"This reduces the time spent searching for documents and managing paper files, while streamlining business processes through automation and integration."}
                            icon={illustration11}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Enhanced Collaboration"}
                            content={"It facilitates better teamwork with real-time editing and sharing, improves communication, and reduces bottlenecks in document workflows."}
                            icon={illustration12}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Improved Security"}
                            content={"The system protects sensitive information with robust access controls and encryption, ensuring compliance with legal and regulatory requirements."}
                            icon={illustration13}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Cost Savings"}
                            content={" It reduces the need for physical storage space and paper usage, while minimizing administrative overhead and operational costs."}
                            icon={illustration14}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Better Decision-Making"}
                            content={"Quick access to accurate and up-to-date information is provided, supporting data-driven decision-making with advanced analytics and reporting."}
                            icon={illustration15}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Scalability and Flexibility"}
                            content={"Easily adapts to the growing needs of a business and offers flexibility to integrate with various other digital tools and platforms."}
                            icon={illustration16}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />


                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default DocumentManagementPage;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  padding: 16px 24px 16px 24px;
  gap: 30px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.list .list-img {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
}
.list .list-img img {
  max-height: 100px;
  height: 100px;
}
.list .list-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list .list-heading {
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-bottom: 20px;
}
.list .list-content {
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
}
@media only screen and (max-width: 991px) {
  .list .list-img {
    text-align: center;
  }
  .list .list-img img {
    height: 100px;
    margin: auto;
  }
  .list .list-heading {
    font-size: 25px;
  }
  .list .list-content {
    text-align: justify;
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/list/List.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACR;AACQ;EACI,iBAAA;EACA,aAAA;AACZ;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AADR;AAII;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAFR;AAKI;EAEI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAJR;AAOI;EACI;IACI,kBAAA;EALV;EAOU;IACI,aAAA;IACA,YAAA;EALd;EASM;IACI,eAAA;EAPV;EAUM;IACI,mBAAA;IACA,eAAA;EARV;AACF","sourcesContent":[".list {\n    padding: 16px 24px 16px 24px;\n    gap: 30px;\n    border-radius: 8px;\n    margin-top: 30px;\n    margin-bottom: 30px;\n\n    .list-img {\n        padding: 20px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        min-width: 150px;\n\n        img {\n            max-height: 100px;\n            height: 100px;\n        }\n    }\n\n    .list-container {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .list-heading {\n        font-size: 25px;\n        font-weight: 700;\n        line-height: 30px;\n        text-align: left;\n        margin-bottom: 20px;\n    }\n\n    .list-content {\n        // min-height: 100%;\n        font-size: 20px;\n        font-weight: 400;\n        line-height: 35px;\n        text-align: left;\n    }\n\n    @media only screen and (max-width: 991px) {\n        .list-img {\n            text-align: center;\n\n            img {\n                height: 100px;\n                margin: auto;\n            }\n        }\n\n        .list-heading {\n            font-size: 25px;\n        }\n\n        .list-content {\n            text-align: justify;\n            font-size: 18px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
//import HomePage from '../pages/HomePage';
import HomePage from '../pages/HomePage';
import ContactUsPage from '../pages/ContactUsPage';

import CustomerRelationsPage from '../pages/domains/CustomerRelationsPage';
import InventoryManagementPage from '../pages/domains/InventoryManagementPage';
import LeadManagementPage from '../pages/domains/LeadManagementPage';
import WebsiteManagementPage from '../pages/domains/WebsiteManagementPage';
import ListingManagementPage from '../pages/domains/ListingManagementPage';
import AboutUsPage from '../pages/AboutUsPage';

import ExperimentsPage from '../pages/features/ExperimentsPage';
import WhatsAppIntegrationPage from '../pages/features/WhatsAppIntegrationPage';
import ArtificialIntelligencePage from '../pages/features/ArtificialIntelligencePage';
import LeadQualificationPage from '../pages/features/LeadQualificationPage';
import DocumentManagementPage from '../pages/features/DocumentManagementPage';
import CalendarManagementPage from '../pages/features/CalendarManagementPage';
import LeadWorkflowPage from '../pages/features/LeadWorkflowPage';
import BusinessOverviewPage from '../pages/features/BusinessOverviewPage';

const PublicRoutes = () => {
    return (
        <Routes>
            <Route exact={true} path="/" element={<HomePage />} />
            <Route exact={true} path="/contact-us" element={<ContactUsPage />} />

            <Route exact={true} path="/domains/customer-relations" element={<CustomerRelationsPage />} />
            <Route exact={true} path="/domains/inventory-management" element={<InventoryManagementPage />} />
            <Route exact={true} path="/domains/lead-management" element={<LeadManagementPage />} />
            <Route exact={true} path="/domains/website-management" element={<WebsiteManagementPage />} />
            <Route exact={true} path="/domains/listing-management" element={<ListingManagementPage />} />
            <Route exact={true} path="/about-us" element={<AboutUsPage />} />

            <Route exact={true} path="/features/experiments" element={<ExperimentsPage />} />
            <Route exact={true} path="/features/whatsapp-integration" element={<WhatsAppIntegrationPage />} />
            <Route exact={true} path="/features/artificial-intelligence" element={<ArtificialIntelligencePage />} />
            <Route exact={true} path="/features/lead-qualification" element={<LeadQualificationPage />} />

            <Route exact={true} path="/features/document-management" element={<DocumentManagementPage />} />
            <Route exact={true} path="/features/calendar-management" element={<CalendarManagementPage />} />
            <Route exact={true} path="/features/lead-workflow" element={<LeadWorkflowPage />} />
            <Route exact={true} path="/features/business-overview" element={<BusinessOverviewPage />} />


            <Route exact={true} path="*"
                element={<HomePage />}
            />
        </Routes>
    );
}

export default PublicRoutes;

import React, { Fragment, useEffect, useState } from 'react';
import './LeadWorkflowPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/lead-workflow/illustration01.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import Card from '../../components/card/Card';

import illustration02 from '../../images/features/lead-workflow/illustration02.svg';
import illustration03 from '../../images/features/lead-workflow/illustration03.svg';
import illustration04 from '../../images/features/lead-workflow/illustration04.svg';
import illustration05 from '../../images/features/lead-workflow/illustration05.svg';
import illustration06 from '../../images/features/lead-workflow/illustration06.svg';
import illustration07 from '../../images/features/lead-workflow/illustration07.svg';
import illustration08 from '../../images/features/lead-workflow/illustration08.svg';
import illustration09 from '../../images/features/lead-workflow/illustration09.svg';
import illustration10 from '../../images/features/lead-workflow/illustration10.svg';
import illustration11 from '../../images/features/lead-workflow/illustration11.svg';
import illustration12 from '../../images/features/lead-workflow/illustration12.svg';
import illustration13 from '../../images/features/lead-workflow/illustration13.svg';
import illustration14 from '../../images/features/lead-workflow/illustration14.svg';

const LeadWorkflowPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Lead Workflow, Escalations and Tasks");
        setList(
            <Fragment>
                <ul>
                    <p>A digital business platform <strong>streamlines</strong> sales operations through <strong>integrated</strong> lead workflow, escalation, and task management. </p>
                    <br />
                    <p>It automates lead capture, assignment, nurturing, and tracking for <strong>timely engagement</strong>. Escalation ensures <strong>high-priority</strong> leads get <strong>prompt</strong> attention, while task management <strong>facilitates coordination</strong> and timely <strong>completion</strong> of activities. </p>
                    <br />
                    <p>This enhances <strong>efficiency</strong>, boosts <strong>conversion rates</strong>, and improves team <strong>collaboration</strong>.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='lead-workflow-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section cards-section mt-5'>
                        <div className='heading'>Integrated Lead Management System: </div>
                        <div className='sub-heading'>Lead Workflow
                            <div className='sub-heading-2'>A systematic process that outlines the steps and stages through which potential customers (leads) move from initial contact to conversion.</div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Capture & Assignment"}
                                    content={"Automates lead capture from channels like websites and social media, assigning them based on criteria such as geography or product interest."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Tracking & Nurturing"}
                                    content={"Tracks leads through the pipeline, using automated, personalised communication to nurture them and improve conversion chances."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Analytics & Insights"}
                                    content={"Provides analytics on conversion rates and lead sources, helping refine marketing and sales strategies."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                        <div className='sub-heading'>Escalation Management
                            <div className='sub-heading-2'>The process of raising an issue or task to a higher level of authority or a more advanced stage in a workflow when it cannot be resolved at its current level.</div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Automated Escalations"}
                                    content={"Automatically escalates issues based on rules like lead inactivity or missed milestones, ensuring prompt attention."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Manual Escalations"}
                                    content={"Allows sales reps to manually escalate leads to higher authority or different departments when they encounter issues that require specialised handling or faster resolution. "}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Notifications & Alerts"}
                                    content={"Sends automated notifications and alerts to the sales team, providing real-time updates on escalations to keep the team informed and enable immediate action."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                        <div className='sub-heading'>Task Management
                            <div className='sub-heading-2'>Specific activities or assignments within a workflow that contribute to the completion of a larger goal. They are typically defined, manageable units of work assigned to individuals or teams.</div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Assignment & Tracking"}
                                    content={"Assigns and tracks tasks like follow-up calls, meetings, or sending additional information, ensuring all necessary actions are taken to move leads through the pipeline efficiently."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration08}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Collaboration"}
                                    content={"Facilitates team collaboration with shared access to lead information, task progress, and notes, ensuring everyone involved in the sales process is on the same page."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration09}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Deadline Management"}
                                    content={"Provides automated reminders and tracks deadlines to ensure tasks are completed on time, maintaining sales momentum and reducing the risk of missed opportunities."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration10}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The benefits of an Integrated Lead Management System:</div>
                        <List
                            heading={"Efficiency"}
                            content={"Automation of lead capture, assignment, nurturing, and escalation reduces manual workload and minimizes errors."}
                            icon={illustration11}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Improved Conversion Rates"}
                            content={"By ensuring that leads are systematically nurtured and followed up on, the platform helps improve conversion rates."}
                            icon={illustration12}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Enhanced Collaboration"}
                            content={"Task management and escalation features promote better coordination and communication among team members."}
                            icon={illustration13}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Data-Driven Decisions"}
                            content={"Comprehensive analytics and reporting provide insights that help refine sales strategies and improve overall performance."}
                            icon={illustration14}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />

                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default LeadWorkflowPage;
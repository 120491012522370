import React, { Fragment, useEffect, useState } from 'react';
import './AboutUsPage.scss';
import PageLayout from '../components/page-layout/PageLayout';
import Title from '../components/title/Title';
import illustration01 from '../images/about-us/illustration01.svg';
import illustration02 from '../images/about-us/illustration02.svg';
import { Dialog } from '@mui/material';
import { DialogTitle, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';


import ContactUs from '../components/contact-us-form/ContactUsForm';

const AboutUsPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("About accelerare");
        setList(
            <Fragment>
                <ul>
                    <p><strong>Welcome</strong> to Accelerare, where <strong>innovation meets efficiency</strong>. Our digital business platform is designed to <strong>streamline</strong> your operations, <strong>enhance</strong> customer engagement, and <strong>drive</strong> your business <strong>growth</strong>. </p>
                    <p><strong>Harness</strong> the power of cutting-edge <strong>technology</strong> and comprehensive solutions to stay ahead in the <strong>competitive</strong> market. Join us and <strong>transform</strong> the way you do <strong>business</strong>.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={"Accelerare | Home"} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='about-us-page'>
                    <div className='banner-component'>
                        <div className='banner-title'>
                            <Title title={title} list={list} />
                        </div>
                        <div class='banner-title-mobile'>
                            <ul>
                                <p><strong>Welcome</strong> to Accelerare, where <strong>innovation meets efficiency</strong>. Our digital business platform is designed to <strong>streamline</strong> your operations, <strong>enhance</strong> customer engagement, and <strong>drive</strong> your business <strong>growth</strong>. </p>
                                <p><strong>Harness</strong> the power of cutting-edge <strong>technology</strong> and comprehensive solutions to stay ahead in the <strong>competitive</strong> market. Join us and <strong>transform</strong> the way you do <strong>business</strong>.</p>
                            </ul>
                            <div className="contact-btn">
                                <button className="btn" onClick={() => setDialogOpen(true)}>Contact Us</button>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="content">
                            <div className="heading">
                                Who we are and what we do
                            </div>
                            <div className="body">
                                <p>At Accelerare, we are pioneers in the digital transformation landscape, dedicated to helping businesses navigate and thrive in the ever-evolving digital world. We create intelligent solutions that empower companies to enhance their operations, connect more effectively with their customers, and make informed decisions based on solid data insights.</p>
                                <p>Our platform is designed to be the backbone of your business, integrating advanced technologies that work seamlessly together to streamline your processes and drive sustainable growth. Backed by rigorous research and a commitment to innovation, we ensure that every tool we offer is built with your success in mind.</p>
                            </div>
                        </div>
                        <div className="section-image">
                            <img src={illustration01} alt="About Us" className="img-fluid" />
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-image">
                            <img src={illustration02} alt="About Us" className="img-fluid" />
                        </div>
                        <div className="content">
                            <div className="heading">
                                Our Vision
                            </div>
                            <div className="body">
                                <p>Our vision is to revolutionise the way businesses operate in the digital era by offering a platform that is not just advanced but also intuitive and scalable. We believe that the future of business lies in harnessing the power of technology to create smarter, more efficient workflows and deliver exceptional customer experiences.</p>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="w-100">
                            <div className="heading">
                                Why use us
                            </div>
                            <div className="body">
                                <p>Choosing Accelerare means choosing a partner who understands the complexities of the modern business environment and is committed to delivering solutions that are both innovative and user-friendly. With our platform, you can expect to stay ahead of industry trends, make data-driven decisions with confidence, and unlock new levels of growth and success.</p>
                            </div>
                        </div>
                    </div>
                    <div className="img-background">
                        <div className="contact-btn">
                            <button className="btn" onClick={() => setDialogOpen(true)}>Contact Us</button>
                        </div>
                    </div>
                    <Dialog open={dialogOpen} fullWidth >
                        <DialogTitle sx={{ fontSize: '30px', fontWeight: '700', paddingLeft: '50px' }}>
                            <div className='d-flex justify-content-between'>
                                Contact Me
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setDialogOpen(false)}
                                >
                                    <CloseOutlined />
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <ContactUs />
                    </Dialog>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default AboutUsPage;
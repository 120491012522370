// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": `#0e1436`,
	"colorSecondary": `#24d594`,
	"colorTertiary": `#063f4c`,
	"colorTertiaryLight": `#37656f`,
	"colorTertiaryLighter": `#698b93`,
	"colorAccent": `#db296b`,
	"colorWhite": `#ffffff`,
	"colorBlack": `#000000`,
	"colorGrey": `#707376`,
	"colorGreyLight": `#dddddd`,
	"colorChart1": `#063f4c`,
	"colorChart2": `#24d594`,
	"colorChart3": `#007595`,
	"colorChart4": `#db296b`,
	"colorChart5": `#0e1436`,
	"colorChart6": `#cbc573`,
	"white": `#ffffff`,
	"grey": `#707376`,
	"greyLight": `#f8f8f8`,
	"navy": `#0e1436`,
	"navyLighter1": `#262b4a`,
	"navyLighter2": `#3e425e`,
	"navyLighter3": `#565a72`,
	"navyLighter4": `#6e7286`,
	"greenDarker4": `#072a1d`,
	"greenDarker3": `#0e553b`,
	"greenDarker2": `#157f58`,
	"greenDarker1": `#1caa76`,
	"green": `#24d594`,
	"greenLighter1": `#4fdda9`,
	"greenLighter2": `#7be5be`,
	"greenLighter3": `#a7eed4`,
	"greenLighter4": `#d3f6e9`,
	"blueGreenDarker3": `#02191e`,
	"blueGreenDarker2": `#03252d`,
	"blueGreenDarker1": `#04323c`,
	"blueGreen": `#063f4c`,
	"blueGreenLighter1": `#37656f`,
	"blueGreenLighter2": `#698b93`,
	"blueGreenLighter3": `#9bb2b7`,
	"blueGreenLighter4": `#cdd8db`
};
export default ___CSS_LOADER_EXPORT___;

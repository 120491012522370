import axios from 'axios';

class TenantService {
    constructor(token) {
        this._defaultOptions = {
            timeout: 30000,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
    }

    getTenantById = async (tenantId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API_URL}/tenant/${tenantId}`, { ...this._defaultOptions });
            return response.data;
        } catch (error) {
            return null;
        }
    }

    getTenantSystems = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API_URL}/tenant/gettenantsystems`, { ...this._defaultOptions });
            return response.data;
        } catch (error) {
            return null;
        }
    }
}

export default TenantService;
import React, { Fragment, useEffect, useState } from 'react';
import './BusinessOverviewPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/business-overview/illustration01.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import Card from '../../components/card/Card';

import illustration02 from '../../images/features/business-overview/illustration02.svg';
import illustration03 from '../../images/features/business-overview/illustration03.svg';
import illustration04 from '../../images/features/business-overview/illustration04.svg';
import illustration05 from '../../images/features/business-overview/illustration05.svg';
import illustration06 from '../../images/features/business-overview/illustration06.svg';
import illustration07 from '../../images/features/business-overview/illustration07.svg';
import illustration08 from '../../images/features/business-overview/illustration08.svg';
import illustration09 from '../../images/features/business-overview/illustration09.svg';
import illustration10 from '../../images/features/business-overview/illustration10.svg';
import illustration11 from '../../images/features/business-overview/illustration11.svg';
import illustration12 from '../../images/features/business-overview/illustration12.svg';

const BusinessOverviewPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Business Overview Dashboard");
        setList(
            <Fragment>
                <ul>
                    <p>A business overview dashboard as a feature of a digital business platform refers to a <strong>user interface</strong> that consolidates and <strong>visualises</strong> critical business <strong>metrics</strong> and performance <strong>indicators</strong> in <strong>real-time</strong>. </p>
                    <br />
                    <p>This feature <strong>transforms</strong> how businesses <strong>handle</strong> performance monitoring and decision-making, leading to <strong>increased</strong> efficiency, accountability, and strategic alignment, while also providing a <strong>foundation</strong> for better business processes and <strong>decision-making</strong>.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='business-overview-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section cards-section mt-5'>
                        <div className='heading'>The key component of this feature: </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Real-Time Visualisation"}
                                    content={"Display real time data that tracks key performance indicators (KPIs) relevant to the business, such as sales figures, customer acquisition rates, website traffic, and financial metrics."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Customisable Widgets"}
                                    content={"Allow users to customise their dashboards to display the most relevant metrics for their roles, enabling easy addition and rearrangement to tailor the dashboard to individual needs."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Integration"}
                                    content={"Connect with various data sources, to pull in relevant data and provide a consolidated view of data from multiple sources for comprehensive insights."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Interactive & Drill-Down"}
                                    content={"Enable users to click on data points to drill down into more detailed information for deeper analysis and understanding, allowing filtering by various dimensions."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Alerts & Notifications"}
                                    content={"Set up alerts to notify users when specific metrics exceed or fall below predefined thresholds, ensuring users are informed of significant changes or trends in real-time."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Collaboration & Sharing"}
                                    content={"Provide access to multiple team members for collaborative analysis and decision-making, with options to export reports and dashboards for presentations or further analysis."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The benefits of lead qualification:</div>
                        <List
                            heading={"Informed Decision-Making"}
                            content={"Access to real-time data allows for quicker, data-driven decisions, helping to identify trends and patterns that inform strategic planning and operational adjustments."}
                            icon={illustration08}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Improved Efficiency"}
                            content={"Reduce the time spent on manually gathering and analyzing data by highlighting the most important metrics and ensuring attention is focused on what matters most."}
                            icon={illustration09}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Enchanced Accountability"}
                            content={"Monitor individual and team performance against set goals and KPIs, promoting transparency by providing a clear view of business performance to all stakeholders."}
                            icon={illustration10}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Proactive Management"}
                            content={"Identify potential issues early and take corrective actions before they escalate, while spotting opportunities for growth and improvement through data analysis."}
                            icon={illustration11}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Strategic Alignment"}
                            content={"Ensure alignment with business goals by continuously monitoring relevant metrics and optimizing resource allocation based on performance data and insights."}
                            icon={illustration12}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default BusinessOverviewPage;
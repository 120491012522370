import React, { Fragment, useEffect, useState } from 'react';
import './WhatsAppIntegrationPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/WhatsAppIntegration2.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';

import illustration11 from '../../images/features/whatsapp-integration/illustration11.svg';
import illustration12 from '../../images/features/whatsapp-integration/illustration12.svg';
import illustration13 from '../../images/features/whatsapp-integration/illustration13.svg';
import illustration14 from '../../images/features/whatsapp-integration/illustration14.svg';
import illustration15 from '../../images/features/whatsapp-integration/illustration15.svg';
import illustration16 from '../../images/features/whatsapp-integration/illustration16.svg';
import illustration17 from '../../images/features/whatsapp-integration/illustration17.svg';
import illustration18 from '../../images/features/whatsapp-integration/illustration18.svg';
import illustration19 from '../../images/features/whatsapp-integration/illustration19.svg';
import illustration20 from '../../images/features/whatsapp-integration/illustration20.svg';

const WhatsAppIntegrationPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("WhatsApp Integration");
        setList(
            <Fragment>
                <ul>
                    <p>WhatsApp integration <strong>facilitates</strong> real-time <strong>communication</strong>, allowing <strong>instant messaging</strong> and <strong>media</strong> sharing.
                        Businesses can use the WhatsApp <strong>Business API</strong> for <strong>automated</strong> messaging,<strong> chatbots</strong>, and direct <strong>customer communication</strong>.</p>
                    <p>Integrating What0sApp with <strong>CRM</strong> systems helps <strong>manage</strong> customer <strong>interactions</strong> and provide <strong>personalised service</strong>.
                        WhatsApp supports <strong>end-to-end encryption</strong>, ensuring <strong>secure</strong> and <strong>private communication</strong> for users and businesses.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='whatsapp-integration-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section mt-5 '>
                        <div className='heading'>The benefits of WhatsApp integration:</div>
                        <List
                            heading={"Direct Communication"}
                            content={"Enables direct and instant communication with customers, facilitating real-time interactions, support, and feedback."}
                            icon={illustration11}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Global Reach"}
                            content={"With a vast user base, WhatsApp provides businesses with a platform to reach a global audience, breaking down geographical barriers."}
                            icon={illustration12}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Customer Support"}
                            content={"Businesses can use WhatsApp as a customer support channel, addressing queries, resolving issues, and providing assistance efficiently."}
                            icon={illustration13}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Marketing and Promotions"}
                            content={"WhatsApp allows businesses to share updates, promotions, and marketing materials directly with customers, fostering engagement and driving sales."}
                            icon={illustration14}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Automation"}
                            content={"Integration with chatbots and automated responses streamlines processes, providing quick answers to common queries and enhancing overall efficiency."}
                            icon={illustration15}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Secure Communication"}
                            content={"WhatsApp employs end-to-end encryption, ensuring the security and privacy of business and customer communications."}
                            icon={illustration16}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Rich Media Sharing"}
                            content={"Businesses can share multimedia content like images, videos, and documents, enhancing the richness of communication and marketing efforts."}
                            icon={illustration17}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Appointment Scheduling"}
                            content={"Integrating WhatsApp with scheduling tools allows businesses to efficiently manage appointments and bookings."}
                            icon={illustration18}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Personalised Engagement"}
                            content={"Businesses can send personalised messages and offers based on customer preferences, improving the overall customer experience."}
                            icon={illustration19}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Cost-Effective"}
                            content={"Compared to traditional communication channels, WhatsApp integration can be a cost-effective solution for businesses to connect with their audience."}
                            icon={illustration20}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default WhatsAppIntegrationPage;
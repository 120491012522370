import React, { Fragment, useEffect, useState } from 'react';
import './ListingManagementPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/domains/ListingManagement.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import illustration01 from '../../images/domains/listing-management/illustration01.svg';
import illustration02 from '../../images/domains/listing-management/illustration02.svg';
import illustration03 from '../../images/domains/listing-management/illustration03.svg';
import illustration04 from '../../images/domains/listing-management/illustration04.svg';
import illustration05 from '../../images/domains/listing-management/illustration05.svg';

const ListingManagementPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Listing Management");
        setList(
            <Fragment>
                <ul>
                    <li>Ensuring that <strong>accurate</strong> and up-to-date <strong>information</strong> about the business, including its name, address, phone number, and hours of operation, is maintained <strong>across various online platforms</strong> and directories. </li>
                    <li>It aims to <strong>enhance</strong> the business's visibility in search engine results and improve its local <strong>search engine optimisation</strong> (SEO) rankings. This is achieved by <strong>optimising business listings</strong> with relevant keywords and ensuring consistency across platforms. </li>
                    <li>It includes <strong>monitoring</strong> and <strong>responding</strong> to <strong>customer reviews</strong> and <strong>inquiries</strong> on multiple platforms to maintain a positive brand reputation and foster trust among potential customers. </li>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='listing-management-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The importance of listing management:</div>
                        <List
                            heading={"Consistency and Accuracy: "}
                            content={"Maintaining consistent and accurate business details across various online directories and platforms fosters trust among customers and search engines."}
                            icon={illustration01}
                            backgroundColor={colors.greyLight}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Search Visibility:"}
                            content={"Enhancing business listings with pertinent keywords, categories, and descriptions boosts local search rankings, facilitating easier discovery for potential customers."}
                            icon={illustration02}
                            backgroundColor={colors.greyLight}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Brand Reputation Management: "}
                            content={"Consistently updating business information across listings enhances brand reputation and credibility by presenting a professional and trustworthy image to potential customers."}
                            icon={illustration03}
                            backgroundColor={colors.greyLight}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Website Traffic:"}
                            content={"Accurate and consistent listings drive more website traffic as users encounter your business information on online directories or search engine results."}
                            icon={illustration04}
                            backgroundColor={colors.greyLight}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Customer Engagement: "}
                            content={"Facilitating customer discovery and contact increases engagement and conversions, as users favor businesses offering convenient and reliable information."}
                            icon={illustration05}
                            backgroundColor={colors.greyLight}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default ListingManagementPage;
import React, { Fragment, useEffect, useState } from 'react';
import './ArtificialIntelligencePage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/ArtificialIntelligence.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import Card from '../../components/card/Card';

import illustration01 from '../../images/features/artificial-intelligence/illustration01.svg';
import illustration02 from '../../images/features/artificial-intelligence/illustration02.svg';
import illustration03 from '../../images/features/artificial-intelligence/illustration03.svg';
import illustration04 from '../../images/features/artificial-intelligence/illustration04.svg';
import illustration05 from '../../images/features/artificial-intelligence/illustration05.svg';
import illustration06 from '../../images/features/artificial-intelligence/illustration06.svg';
import illustration07 from '../../images/features/artificial-intelligence/illustration07.svg';
import illustration08 from '../../images/features/artificial-intelligence/illustration08.svg';
import illustration09 from '../../images/features/artificial-intelligence/illustration09.svg';
import illustration10 from '../../images/features/artificial-intelligence/illustration10.svg';
import illustration11 from '../../images/features/artificial-intelligence/illustration11.svg';
import illustration12 from '../../images/features/artificial-intelligence/illustration12.svg';


const ArtificialIntelligencePage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Artificial Intelligence");
        setList(
            <Fragment>
                <ul>
                    <p>Artificial Intelligence (AI) is a <strong>transformative</strong> feature in digital business platforms, offering a range of <strong>capabilities</strong> that <strong>enhance</strong> business <strong>operations</strong>, improve customer <strong>experiences</strong>, and drive <strong>innovation</strong>.</p>
                    <br />
                    <p>AI also provides a <strong>competitive edge</strong> by leveraging advanced <strong>technologies</strong> to enhance <strong>decision-making</strong>, customer engagement, and overall <strong>efficiency</strong>.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='artificial-intelligence-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The benefits of incorporating this feature:</div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Efficiency and Automation"}
                                    content={"AI automates repetitive tasks, streamlining operations, and freeing up human resources for more strategic and creative activities."}
                                    backgroundColor={colors.white}
                                    icon={illustration01}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Data Analysis"}
                                    content={"AI can analyse large datasets quickly, extracting valuable insights that inform decision-making and enhance business intelligence."}
                                    backgroundColor={colors.white}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Customer Experience"}
                                    content={"AI-powered chatbots and virtual assistants provide immediate and personalised customer support, improving overall customer satisfaction."}
                                    backgroundColor={colors.white}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Predictive Analytics"}
                                    content={"AI algorithms can forecast trends and behaviour patterns, helping businesses anticipate market changes, demand fluctuations, and customer preferences"}
                                    backgroundColor={colors.white}
                                    icon={illustration04}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Cost Reduction"}
                                    content={"By automating tasks and improving operational efficiency, AI can lead to cost savings in various aspects of business operations."}
                                    backgroundColor={colors.white}
                                    icon={illustration05}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Personalisation"}
                                    content={"AI enables businesses to deliver personalised experiences, from targeted marketing campaigns to customised product recommendations, enhancing customer engagement."}
                                    backgroundColor={colors.white}
                                    icon={illustration06}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Risk Management"}
                                    content={"AI algorithms can analyse and identify potential risks, fraud, or security threats, contributing to better risk management strategies."}
                                    backgroundColor={colors.white}
                                    icon={illustration07}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Innovation & Productivity"}
                                    content={"Fosters innovation by enabling the development of new products, services, and business models. It also enhances productivity by augmenting human capabilities."}
                                    backgroundColor={colors.white}
                                    icon={illustration08}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Competitive Advantage"}
                                    content={"Businesses that leverage AI gain a competitive edge by staying agile, adapting to market changes, and adopting innovative solutions faster than competitors."}
                                    backgroundColor={colors.white}
                                    icon={illustration09}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Scalability"}
                                    content={"Can handle increasing workloads and scale with business growth, ensuring adaptability in dynamic market environments."}
                                    backgroundColor={colors.white}
                                    icon={illustration10}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Employee Empowerment"}
                                    content={"Assists employees in tasks, making them more productive, and allowing them to focus on tasks that require creativity, critical thinking, and emotional intelligence."}
                                    backgroundColor={colors.white}
                                    icon={illustration11}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"24/7 Operations"}
                                    content={"AI systems can operate around the clock, providing continuous services and support, which is particularly beneficial in global markets with different time zones."}
                                    backgroundColor={colors.white}
                                    icon={illustration12}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>

                        </div>
                    </div>
                </div>
            </PageLayout>
        </Fragment >
    );
}

export default ArtificialIntelligencePage;
import axios from 'axios';

class SendgridService {
    constructor() {
        this._defaultOptions = {
            timeout: 30000
        };
    }

    sendEmail = async (body) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_PUBLIC_API_URL}/sendgrid`, body, { ...this._defaultOptions });
            return response.data;
        } catch (error) {
            return null;
        }
    }
}

export default SendgridService;
import React, { Fragment, useEffect, useState } from 'react';
import './LeadQualificationPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/features/LeadQualification.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import Card from '../../components/card/Card';

import illustration01 from '../../images/features/lead-qualification/illustration01.svg';
import illustration02 from '../../images/features/lead-qualification/illustration02.svg';
import illustration03 from '../../images/features/lead-qualification/illustration03.svg';
import illustration04 from '../../images/features/lead-qualification/illustration04.svg';
import illustration05 from '../../images/features/lead-qualification/illustration05.svg';
import illustration06 from '../../images/features/lead-qualification/illustration06.svg';
import illustration07 from '../../images/features/lead-qualification/illustration07.svg';
import illustration08 from '../../images/features/lead-qualification/illustration08.svg';
import illustration09 from '../../images/features/lead-qualification/illustration09.svg';

const LeadQualificationPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Lead Qualification");
        setList(
            <Fragment>
                <ul>
                    <p>The process of <strong>evaluating potential</strong> customers (leads) to determine their <strong>likelihood</strong> of becoming <strong>paying</strong> customers. It involves <strong>assessing</strong> factors like the lead's <strong>need</strong> for the product or service, their <strong>budget</strong>, decision-making <strong>authority</strong>, and <strong>timing</strong>. </p>
                    <br />
                    <p><strong>Qualified</strong> leads are those who <strong>meet</strong> specific <strong>criteria</strong> indicating they are ready and able to make a <strong>purchase</strong>, helping sales teams <strong>prioritise</strong> their efforts on the most <strong>promising prospects</strong>.</p>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='lead-qualification-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section cards-section mt-5'>
                        <div className='heading'>More about this feature: </div>
                        <div className='row'>
                            <div className='col-12 col-md-6 col-lg-4 my-2 '>
                                <Card
                                    heading={"Evaluation Process"}
                                    content={"Assessing potential customers based on specific criteria such as need, budget, authority, and timeline to determine their readiness to buy."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration01}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Prioritisation"}
                                    content={"It helps prioritise leads so efforts can be focused on prospects most likely to convert, ensuring efficient use of time and resources."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration02}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-2'>
                                <Card
                                    heading={"Alignment and Clarity"}
                                    content={"Better alignment between website and sales teams by establishing a clear definition of what constitutes a qualified lead, improving overall sales effectiveness."}
                                    backgroundColor={colors.greyLight}
                                    icon={illustration03}
                                    headingColor={colors.navy}
                                    contentColor={colors.navy} />
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid first-section mt-5'>
                        <div className='heading'>The benefits of lead qualification:</div>
                        <List
                            heading={"Increased Efficiency"}
                            content={"By identifying and focusing on leads with the highest potential, sales teams can streamline their efforts. This prevents wasting time on unqualified leads and allows sales representatives to allocate their time and resources more effectively, enhancing overall productivity."}
                            icon={illustration04}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Improved Conversion Rates"}
                            content={"Lead qualification helps prioritize leads that are more likely to convert into customers. By targeting these high-potential leads, sales teams can increase their success rates, leading to a higher number of closed deals and improved revenue."}
                            icon={illustration05}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Enhanced User Experience"}
                            content={"Qualified leads receive tailored and relevant information that addresses their specific needs and pain points. This personalized approach improves their buying journey, making them feel understood and valued, which can result in higher user satisfaction and loyalty."}
                            icon={illustration06}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Better Sales Insights"}
                            content={"The lead qualification process generates valuable data on lead behaviour, preferences, and engagement. Analysing this data helps sales and marketing teams refine their strategies, understand market trends, and develop more targeted and effective campaigns."}
                            icon={illustration07}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Better Resource Allocation"}
                            content={"By focusing on qualified leads, companies can allocate their marketing and sales resources more effectively. This maximizes return on investment (ROI) by ensuring that efforts and budgets are directed toward prospects with the highest potential for conversion."}
                            icon={illustration08}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={"Reduces Sales Cycle Length"}
                            content={"Qualified leads are typically more prepared and ready to make a purchase. By targeting these leads, the sales process can be expedited, reducing the overall length of the sales cycle. This leads to quicker deal closures and faster revenue generation."}
                            icon={illustration09}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />

                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default LeadQualificationPage;
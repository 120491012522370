import React, { Fragment, useEffect, useState } from 'react';
import './CustomerRelationsPage.scss';
import PageLayout from '../../components/page-layout/PageLayout';
import icon from '../../images/domains/CustomerRelations.svg';
import Title from '../../components/title/Title';
import colors from '../../_variables.module.scss';
import List from '../../components/list/List';
import illustration01 from '../../images/domains/customer-relations/illustration01.svg';
import illustration02 from '../../images/domains/customer-relations/illustration02.svg';
import illustration03 from '../../images/domains/customer-relations/illustration03.svg';
import illustration04 from '../../images/domains/customer-relations/illustration04.svg';


const CustomerRelationsPage = () => {
    const [title, setTitle] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        setTitle("Customer Relations");
        setList(
            <Fragment>
                <ul>
                    <li>Designed to meet the <strong>diverse needs</strong> of modern businesses. </li>
                    <li>A comprehensive package of <strong>practices</strong>, <strong>strategies</strong>, and <strong>cutting-edge technologies</strong>. </li>
                    <li>It <strong>empowers</strong> businesses to efficiently <strong>manage</strong> and <strong>analyse</strong> customer interactions across the entire <strong>customer lifecycle</strong>. </li>
                    <li>The ultimate aim of <strong>enhancing</strong> customer <strong>satisfaction</strong> and <strong>nurturing</strong> enduring <strong>relationships</strong>.</li>
                </ul>
            </Fragment>
        )
    }, []);

    return (
        <Fragment>
            <PageLayout title={`Accelerare | ${title}`} description={"Accelerare. Increasing the success of online lead generation strategies. Ride the wave of success that user experience can bring. Resulting in business value growth."}>
                <div className='customer-relations-page'>
                    <Title title={title} list={list} icon={icon} />
                    <div className='container-fluid first-section mt-5'>
                        <div className='d-flex flex-sm-row flex-column justify-content-sm-between'>
                            <img src={illustration01} alt='Customer Relations' className='img-fluid' />
                            <div>
                                <div className='heading'>What is customer relations</div>
                                <div className='text'>
                                    <p>Customer Relations or Customer Relationship Management (<strong>CRM</strong>) involves the <strong>collection</strong> and <strong>analysis</strong> of customer data to <strong>streamline interactions</strong>, <strong>automate tasks</strong>, and <strong>enhance customer satisfaction</strong>, ultimately <strong>driving business growth</strong> through improved relationships and targeted marketing efforts. </p>
                                    <p><strong>Key components</strong> include data collection, customer interaction management, analytics and reporting, automation, marketing automation, sales force automation, and customer service and support functionalities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid second-section'>
                        <div className='heading'>The benefits of this domain</div>
                        <List
                            heading={null}
                            content={"Customer Relations helps to enhance customer relationships, improve communication and collaboration across departments, increase efficiency and productivity by automating tasks, enable data-driven decision-making, facilitate targeted marketing and sales efforts, and support scalability and growth."}
                            icon={illustration02}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={null}
                            content={"Establishing and sustaining strong relationships with your customers offers the distinct advantage of facilitating smoother adjustments to market fluctuations. The strong relationship formed between business and customer can foster more meaningful interactions and ultimately customer loyalty. Additionally this strong relationship with customers can create open communication and ensure that friction point to a positive overall experience is identified and improved. "}
                            icon={illustration03}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                        <List
                            heading={null}
                            content={"Ultimately, not incorporating CRM into a business may result in disjointed customer interactions, limited insights, poor customer satisfaction, inefficient processes, missed revenue opportunities, difficulty scaling operations, and loss of competitive advantage, especially in the predominantly digital global market where customer are spoilt for choice. "}
                            icon={illustration04}
                            backgroundColor={colors.white}
                            headingColor={colors.navy}
                            contentColor={colors.navy} />
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
}

export default CustomerRelationsPage;